html {
  height: 100%;
  overflow: auto;
}

body {
  background: linear-gradient(153.43deg, #EB1C31 0%, #48276C 100%);
  background-repeat: no-repeat;
  height: 100%;
  overflow: auto;
  font-family: $font-family-base;
}

*, ::after, ::before {
  box-sizing: border-box;
}

hr {
  border: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5) ;
  width: 100%;
}