@import '../theme/variables';

$card-border-radius: 4px;
$card-md-width: 370px;
$card-img-dimension: 80px;

.card {
  border-radius: $card-border-radius;
  background-color: $white;
  padding: 16px;
  width: $card-md-width;

  &.small {
    width: 100%;
  }

  .card-img {
    background: $white;
    width: $card-img-dimension;
    height: $card-img-dimension;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto -55px;
    border: solid 4px $white;
    position: relative;
    top: -55px;

    img {
      max-width: 100%;
    }
  }

  @include media-breakpoint-down(sm) { 
    width: 100%;
  }
}