@import '../theme/variables';

.dialogs-container{
  position: fixed;
  z-index: 10;

  &.opened {
    left: 0;
  }
}

.info-dialog{
  padding: 16px;
  background-color: rgba(14, 7, 21, .75);
  border-radius: 4px;

  &.centered {
    left: 0;
    right: 0;
    max-width: 500px;
    margin: 16px auto;
  }

  &.alert {
    background-color: rgba(248, 6, 19, .75);
  }
}

.full-height-dialog {
  padding: 16px;
  width: 310px;
  background-color: #26202C;
  height: 100vh;
  overflow-y: auto;
}